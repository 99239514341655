
/* styling scrollbars*/

  /*general syling*/
*{
  margin: 0;
  padding: 0;
}
body{
  box-sizing: border-box;
  font-family: "sans-serif";
  padding:0;
    margin: 0%;
    margin-bottom: 100px;
   clear: both;
}
body {
  overflow-x: hidden;
  max-width: 100%;
}

.text-primary-p{
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}
.font-bold{
  font-weight: 700;
}
.text-title{
  color: #2e4a66;
}
.text-lightblue{
  color: #469cac;
}
.text-red{
  color: red;
}
.text-yellow{
  color: yellow;
}
.text-green{
  color: rgb(206, 219, 206);
}
.container-fluid{
  display: grid;
  height: 100vh;
  grid-template-columns: 0.6fr 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas: 
  "sidebar nav nav nav"
  "sidebar main main main";
}
@media screen and (max-width: 978px){
 .container{
     grid-template-columns: 1fr;
     grid-template-rows: 0.2fr 3fr;
     grid-template-areas: 
     "nav"
     "main";
 } 
}