.footer{
    background-color: rgb(250, 246, 246);
    grid-area: nav;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    border-bottom: 1px solid lightgrey;
    position: fixed;
  bottom: 0;
  width: 90%;
  left:200px;
  z-index: 1;
  margin-top: 50px;
  clear: both;
}
.usericon{
    font-size: 18px;
}
.mobilebtn{
    color: white;
    font-size: 25px;
}
.nav_icon{
    display: none;
}
.nav_icon > i{
    font-size: 26px;
    color: #a5aaad;
}
.navbar_left > a{
margin-right: 30px;
text-decoration: none;
color: #a5aaad;
font-size: 15px;
font-weight: 700;
}
.navbar_left.active_link{
   color: #265acc;
   border-bottom: 3px solid #265acc;
   padding-bottom: 12px;
    }
.navbar_left.active_link{
   color: #265acc;
   border-bottom: 3px solid #265acc;
   padding-bottom: 12px;
    }
.navbar_rightnew{
        display: flex;
     align-items: center;
     padding-top: 10px;
       position: absolute;
       right: 250px;
        }
 .navbar_right a{
        margin-left: 20px;
        text-decoration: none;
}
.navbar_right a i{
    color: #a5aaad;
    font-size: 16px;
    border-radius: 50px;
    background: #ffffff;
    box-shadow: 2px 2px 5px #d9d9d9 -2px -2px 5px #ffffff;
    padding: 7px;
}
.adminmenu{
    box-shadow: #0000003d 0px 3px 8px;
    padding: 10px;
    position: absolute;
    right: 100px;
    top: 55px;
}
.adminactive{
    display: none;
    background-color: black;
}
@media screen and (max-width: 978px){
    .nav_icon{
       display: inline;
    } 
 }
 @media screen and (max-width: 960px){
    .navbar_left{
       display: none;
    } 
    .footer{
       
        grid-area: nav;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 30px;
        border-top: 1px solid lightgrey;
        position: fixed;
      bottom: 0;
      width: 100%;
      left:0px;
      
    }
    .adminmenu{
        box-shadow: #0000003d 0px 3px 8px;
        padding: 10px;
        position: absolute;
        right: 30px;
        top: 55px;
        background-color: white;
    }
    .navbar_rightnew{
       display: inline;
       width: 100%;
       left:0px;
        }
        .usericon{
            font-size: 18px;
            color: white;
        }
 }