.navbar{
    background-color: white;
    grid-area: nav;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    border-bottom: 1px solid lightgrey;
    position: fixed;
  top: 0;
  width: 90%;
  left:200px;
  z-index: 1;
}
.usericon{
    font-size: 18px;
    display: flex;
    align-items: center;
}
.mobilebtn{
    color: white;
    font-size: 25px;
}
.nav_icon{
    display: none;
}
.nav_icon > i{
    font-size: 26px;
    color: #a5aaad;
}
.navbar_left > a{
margin-right: 30px;
text-decoration: none;
color: #a5aaad;
font-size: 15px;
font-weight: 700;
}
.navbar_left.active_link{
   color: #265acc;
   border-bottom: 3px solid #265acc;
   padding-bottom: 12px;
    }
.navbar_left.active_link{
   color: #265acc;
   border-bottom: 3px solid #265acc;
   padding-bottom: 12px;
    }
.navbar_right{
        display: flex;
     
       position: absolute;
       right: 90px;
        }
 .navbar_right a{
        margin-left: 20px;
        text-decoration: none;
}
.navbar_right a i{
    color: #a5aaad;
    font-size: 16px;
    border-radius: 50px;
    background: #ffffff;
    box-shadow: 2px 2px 5px #d9d9d9 -2px -2px 5px #ffffff;
    padding: 7px;
}
.adminmenu{
    box-shadow: #0000003d 0px 3px 8px;
    position: absolute;
    right: 100px;
    top: 55px;
    background-color: white;
    height: 100px;
}
.dotsdiv{
    box-shadow: #0000003d 0px 3px 8px;
    position: absolute;
    right: 0px;
    top: 22px;
    height: 100px;
    display: none;
}
.dotsdivactive{
    
    position: absolute;
    right: -20px;
    top: -3px;
   
    height: 100px;
    display: block;
}
.ulpadding{
    padding: 3px;
    text-decoration: none;
    cursor: pointer;
}
.uldiv{
    padding: 3px;
    text-decoration: none;
    box-shadow: #0000003d 0px 3px 8px;
    background-color: #ffffff;
    position: relative;
    right: 0px;
    top: -3px; 
    cursor: pointer;
    width: 100px;
    z-index: 10;
    text-decoration: none;
    box-shadow: #0000003d 0px 3px 8px;
}
.uldiv:hover{
    cursor: pointer;
}
.fixwidth{
    height: 30px;
    float: right;
}
.adminmenu1{
    box-shadow: #0000003d 0px 3px 8px;
    
    position: absolute;
    right: 230px;
    top: 65px;
    background-color: #ffffff;
    width: 250px;
    border-radius: 5px;
}
.anglebtn{
    position: absolute;
    right: 20px;
    top: -10px;
    color: white;
}
.req{
    position: absolute;
    top: -10px;
    left: -50px;
   
   display: none;
}
.mgn{
    margin-right: 50px;
}
.mgn:hover .req{
    display: block;
}
.noti{
    position: absolute;
    background-color: red;
    color: white;
    border-radius: 50%;
    font-size: 10px;
    padding-left: 5px;
    width: 15px;
    height: 15px;
}
.navbtn{
    border-radius: 20px;
    background-color: rgb(66, 66, 185);
    border: none;
}
.adminactive{
    display: none;
    background-color: white;
}
@media screen and (max-width: 978px){
    .nav_icon{
       display: inline;
    } 
 }
 @media screen and (max-width: 960px){
    .navbar_left{
       display: none;
    } 
    .noti{
        position: absolute;
        background-color: red;
        color: white;
        border-radius: 50%;
        font-size: 10px;
        padding-left: 5px;
        width: 15px;
        height: 15px;
       
    }
    .navbar{
        background: #1E90FF;
        grid-area: nav;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 30px;
        border-bottom: 1px solid lightgrey;
        position: fixed;
      top: 0;
      width: 100%;
      left:0px;
      
    }
    .navbtn{
        display: none;
    }
    .adminmenu{
        box-shadow: #0000003d 0px 3px 8px;
        padding: 10px;
        position: absolute;
        right: 30px;
        top: 55px;
        background-color: white;
    }
    .navbar_right{
        display: flex;
       position: absolute;
       right: 5%;
        }
        .usericon{
            font-size: 18px;
            color: white;
        }
 }