.container {
    
    background-color: #eeeded;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  
  .tab-list {
    height: 40px;
    display: flex;
    list-style: none;
    font-size: 18px;
    padding: 0;
    margin: 0;
  }
  
  .tabs {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #80808013;
    position: relative;
    cursor: pointer;
   
  }
  
  .tabs:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.3);
  }
  
  .active-tabs {
    background-color: #85C1E9;
    
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 4px;
    background: #5893f1;
  }
  
  .content1 {
      display: none;
      background-color: #85C1E9;
      padding: 10px;
  }
  
  .active-content {
      display: flex;
  }