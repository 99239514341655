.deletebtns{
  display: block;
    position: relative;
    right: 5px;
    bottom: 1%;
   float: right;
}
.centermodal{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 500px;
    
  }
  .pgnav{
    width: 50%;
    background-color: black;
  }
  .theader{
   width: 100%;
  background-color: #AED6F1;
  }
  .react-bootstrap-table table {
    table-layout: auto;
  }
  .modal1{
    width:50%;
    font-size: 18px;
    padding-bottom: 50px;
    margin: 0 auto; 
    border-radius: 0%;
  }
  .modalbody{
    height: 120px;
    font-size: 12px;
  }
  .modalbody1{
    height: auto;
    font-size: 12px;
  }
  @media screen and (max-width: 960px) {
    .modalbody{
     
      font-size: 12px;
      height : max-content;
    }
    .modalbody1{
     
      font-size: 12px;
      height : max-content;

    }
  }