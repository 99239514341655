.whitelabel{
    border-color: white;
}
.spinnerdiv{
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}
.box{
    display: flex;
    flex-wrap: nowrap;
}