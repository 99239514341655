.loginscreen{
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
    box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
    background-color: #fefefe;
    margin: 5% auto 15% auto;
    border: 1px solid #888;
    font-size: 17px;
    width: 50%;
}
.title{
    margin-left: auto;
  margin-right: auto;
  width: 150px;
  color:#1E90FF
}
.primary{
    width: 100%;
    background-color: #1E90FF;
}
.footer{
width: 100%;
padding-bottom: 10px;
}
@media screen and (max-width: 960px) {
    .loginscreen{
width: 80%;
margin-top:20%;

    }

}