.ordersnumber{
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  .row{
    margin-top: 0%;
    padding-top: 0%;
  }
  .col{
    margin-top: 0%;
    padding-top: 0%;
  }
  .blackme{
    background-color: black;
  }
  .bodysection{
 
    position:relative;
    top: 10%;
    left: 4%;
    margin-left:5%;
    margin-top:1%;
    background-color: white;
    padding: 0;
    margin-bottom: 5%;
    background-color: #85C1E9;
    box-shadow: 0 3px 10px;
    margin-bottom: 100px;
    clear: both;
  }
  .bodysection3{
    position: relative;
    top: 10%;
    left: 2%;
    margin-left:7%;
    margin-top:2%;
    padding-left: 2%;
    margin-bottom: 5%;
    margin-bottom: 100px;
    clear: both;
  }
  .bodysection4{
    background-color: #85C1E9;
    position: relative;
    top: 10%;
    left: 4%;
    margin-left:5%;
    margin-top:1%;
    padding: 0;
    margin-bottom: 5%;
    box-shadow: 0 3px 10px;
    margin-bottom: 100px;
    clear: both;
  }
  .dots{
    padding: 3px;
    height: 28px;
    display: flex;
    align-items: center;
   margin-top: 3px;
   cursor: pointer;
   position: sticky;
  }
  .wrapper {
    position: relative;
  }
  .bodysection2{
    position: fixed;
    overflow-y: auto;
    top: 15%;
    left: 14%;
    margin-left:5%;
    margin-top:1%;
    background-color: #85C1E9;
    padding: 0;
    margin-bottom: 5%;
    box-shadow: 0 3px 10px;
    bottom:0;
    margin-bottom: 100px;
    clear: both;
  }
  .bodysection7{
    position: fixed;
    height: 280px;
    top: 15%;
    left: 14%;
    margin-left:5%;
    margin-top:1%;
    background-color: #85C1E9;
    padding: 0;
    margin-bottom: 5%;
    box-shadow: 0 3px 10px;
    bottom:0;
    margin-bottom: 100px;
    clear: both;
  }
  .bodysection6{
    position: relative;
    top: 7%;
    left: 4%;
    margin-left:5%;
    margin-top:1%;
    background-color: #85C1E9;
    padding: 0;
    margin-bottom: 5%;
    box-shadow: 0 3px 10px;
    bottom:0;
    margin-bottom: 100px;
    clear: both;
  }

  .path{
    position: relative;
    top: 25%;
    left: 9%;
    margin-top: 2%;
  }
  .bodysection1{
    position: absolute;
    top: 15%;
    left: 7%;
    margin-left:5%;
   
  }
  .orderscol{
    box-shadow: #0000003d 0px 3px 8px;
    border-radius: 5px;
    height: 100px;
    margin-left: 2%;
    margin-top: 10px;
    max-width: 220px;
    background-color: white;
  }
  .ordersrow{
width: 175px;
margin-left: 10px;
padding-bottom: 5px;
  }
  .col1{
    max-width: 200px;
  }
  .submenu{
    background-color: grey;
    padding: 5px;
  }
  .invdetails{
    display: flex;
    justify-content: space-between;
    
  }
  .rowbd{
    border: 1px solid grey;
    display: flex;
    flex-wrap: nowrap;
  }
  .rowbd1{
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
  }
  .rowbd2{
    border-bottom: 1px solid grey;
   
  }
  .allcont{
    border: 1px solid grey;
  }
  .printme {
    display: none;
  }
  @media print {
    .no-printme  {
      display: none;
    }
    .printme  {
      display: block;
    }
  }
  .testmenow{
    background-color: black;
    z-index: -1; 
  }
  .stbg{
    background-color: #72A0C1;
    color: white;
    font-size: 35px;
  }
  .stbg1{
    background-color: #1560bd;
    color: white;
    font-size: 35px;
  }
  .stbg2{
    background-color: #008E97;
    color: white;
    font-size: 35px;
  }
  .stbg3{
    background-color: #1034A6;
    color: white;
    font-size: 35px;
  }
  @media screen and (max-width: 960px) {
    .bodysection3{
      position: relative;
      top: 10%;
      left: -7%;
      margin-left:3%;
      margin-top:2%;
      padding-left: 2%;
      margin-bottom: 10%;
     
    }
    .bodysection1{
      position: absolute;
      top: 12%;
      left: -5%;
      margin-left:5%;
     
    }
    .bodysection{
      position: relative;
      top: 10%;
      left: 0%;
      margin-left:0%;
    padding-left: 0%;
    }
    
    .qtysize{
      width: 10px;
    }
    
    .bodysection6{
      position: relative;
      top: 10%;
      left: 0%;
      margin-left:0%;
    padding-left: 0%;
    margin-bottom: 100px;
    clear: both;
    
    }
    .bodysection2{
  
      margin-left:-10%;
      padding-left: 0%;
      width: 100%;

    }
    .bodysection2 input{
  
     width: 100%;
    
    }
    .mobilecenter{
      margin: 0 auto;
     text-align: center;
    }
    .mobile{
      display: none;
    }
    .mobilediv{
      padding-right: 20px;
    }
    .orderscol{
      box-shadow: #0000003d 0px 3px 8px;
      border-radius: 5px;
      height: 100px;
      
      margin-top: 10px;
      min-width: 300px;
    }
    .ordersrow{
      width: 100%;
      margin-left: 10px;
    }
    .bodysection4{
      background-color: white;
      position: relative;
      top: 15%;
      left: 1%;
      margin-left:-1%;
      margin-top:2%;
      padding-left: 2%;
      margin-bottom: 5%;
     
    }
    .card-deck{
      justify-content: center;
      background-color: #D6EAF8;
      width: 100%;
    }
    .card{
      justify-content: center;
    }
 }