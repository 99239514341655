.studenthealth{
position: absolute;
left: 1px;
margin-bottom: 2px;
}
.newselect{
    height: 35px;
    width: 100%;
    border: none;
    margin-top: 0%;
}
.newselect1{
    height: 35px;
    width: 100%;
    
}
.Tabs {
    width: 70%;
    height: auto;
 
    padding: 2rem 1rem;
   
    @media (max-width:  480ppx) {
     padding: 2rem 0;
     width: 100%;
    }
   }

   /* Tab Navigation */
ul.nav {
    width: 40%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #39A2DB;
    border-radius: 2rem;
    
    padding-left: 0px;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  ul.nav li:first-child {
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
  }
   
  ul.nav li:last-child {
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
  }
  ul.nav li {
    width: 32%;
    padding-bottom: 10%;
    height: 30px;;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    @media (max-width: 768px) {
        width: 90%;
      }
  }
  ul.nav li:nth-child(2) {
    border-radius: 0;
   
  }
  ul.nav li:hover {
    background: rgba(50, 224, 196, 0.15);
  }
  ul.nav li.active {
    background: #39A2DB;
  }

  /* First and Second Tab Styles */
.FirstTab p,
.SecondTab p {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 10px;
}

.mtop{
   
}
.testme{
    border-radius: 0px;
    -webkit-appearance: none;
}
.success{
    z-index: 1;
    position: absolute;
    right: 8%;
    top: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color:#4FBF26;
    color: white;
    height: 45px;
    width: 190px;
    font-size: 12px;
    border-radius: 5px;
}
.errmessage{
    color: red;
    
}
.errmessagehide{
    color:#85C1E9;
   
    padding: 0;
}
.errmessage1{
  color: red;
  padding-bottom: -18%;
}
.errmessagehide1{
  color:#85C1E9;
  padding-bottom: -18%;
  padding: 0;
}
.ckpd{
  
  margin-bottom: -180px;
}
.btnwidth{

  max-height: 40px;
 margin-bottom: 10px;
}
.errmessagehide2{
  color:white;
  margin-bottom: -8%;
  padding: 0;
}
.errorborder{
border-color: red;

}
.row{
    margin-bottom: -20px;
   
}
.select input {
    font-family: "Times New Roman";
  }
body {
    height: 100%;
    top: 0;
    bottom:0;
    overflow-y:scroll;
    overflow-x:hidden;
    font-family: "Times New Roman";
    font-size: 18px;
}
.datewidth{
    
}
.successhide{
    display: none;
    z-index: 1;
    position: absolute;
    right: 8%;
    top: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color:#4FBF26;
    color: white;
    height: 45px;
    width: 190px;
    font-size: 12px;
    border-radius: 5px;
}
.succcessicon{
    color: white;
    font-size: 25px;
}
@media screen and (max-width: 960px) {
    .studenthealth{
        position: absolute;
        left: -8px;
        margin-bottom: 2px;
        width: 70%;
        }
        .Tabs{
          width: 100%;
        }
        .btnmenu{
          margin: 2px;
        }
}